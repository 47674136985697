import PropTypes from "prop-types";
import React from "react";
import { injectIntl, intlShape, defineMessages, FormattedMessage } from "react-intl";
import _isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import { Badge, Link, Card, Title, Button } from "@yoast/ui-library";
import { generatePluginChangelogUrl } from "shared-frontend/functions/products";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import * as styles from "./DownloadItemStyles.scss";

const messages = defineMessages( {
	downloadButton: {
		id: "product.buttons.download",
		defaultMessage: "Download",
	},
	version: {
		id: "product.product.version",
		defaultMessage: "Version",
	},
	changelog: {
		id: "product.product.changelog",
		defaultMessage: "Changelog",
	},
	composer: {
		id: "product.product.composer",
		defaultMessage: "Install with Composer",
	},
} );

/**
 * The Product component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The rendered Product component.
 */
function DownloadItem( props ) {
	let productVersion = "";
	if ( ! _isEmpty( props.currentVersion ) ) {
		productVersion = props.intl.formatMessage( messages.version ) + " " + props.currentVersion;
	}

	let composerDownload = null;

	if ( includes( [ "plugin", "typo3-extension" ], props.type ) ) {
		composerDownload =
			<p>
				<Link
					as="button"
					onClick={ () => {
						props.onComposerHelpModalOpen( props.name, props.glNumber, props.composerToken );
					} }
				>
					<FormattedMessage { ...messages.composer } />
				</Link>
			</p>;
	}

	return (
		<Card>
			<div className={ styles.downloadItemHeader }>
				<Title as="h2" size="4">{ props.name.replace( "plugin", "" ).trim() }</Title>
			</div>
			<Card.Content>
				<div className={ styles.downloadItemMeta }>
					<Badge as="span" variant="plain">{ productVersion }</Badge>
					<p>
						<Link as="a" href={ generatePluginChangelogUrl( props.name ) } target="_blank" className={ styles.downloadItemLink }>
							<FormattedMessage { ...messages.changelog } />
							<ArrowTopRightOnSquareIcon className={ styles.downloadItemLinkIcon } />
						</Link>
					</p>
				</div>
				{ props.icon && <img className={ styles.downloadItemImage } src={ props.icon } alt="" /> }
			</Card.Content>
			<Card.Footer className={ styles.downloadItemFooter }>
				{ props.buttons.map( button => {
					return (
						<p key={ button.label }>
							<Button
								as="a"
								href={ button.file }
								aria-label={ props.intl.formatMessage( messages.downloadButton ) + " " + props.name + " " + button.label }
							>
								<ArrowDownTrayIcon className={ styles.downloadItemButtonIcon } />
								<FormattedMessage { ...messages.downloadButton } />
							</Button>
						</p>
					);
				} ) }
				<p>or</p>
				{ composerDownload }
			</Card.Footer>
		</Card>
	);
}

DownloadItem.propTypes = {
	name: PropTypes.string.isRequired,
	glNumber: PropTypes.string,
	currentVersion: PropTypes.string,
	icon: PropTypes.string.isRequired,
	buttons: PropTypes.array.isRequired,
	intl: intlShape.isRequired,
	composerToken: PropTypes.object,
	onComposerHelpModalOpen: PropTypes.func,
	type: PropTypes.string,
};

export default injectIntl( DownloadItem );

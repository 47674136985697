import { connect } from "react-redux";
import _uniqBy from "lodash/uniqBy";

import SitePage from "../components/SitePage";
import { loadSites, updateSiteUrl } from "shared-frontend/redux/actions/sites";
import {
	siteAddSubscription,
	siteChangePlatform,
	siteRemove,
	siteRemoveSubscription,
} from "shared-frontend/redux/actions/site";
import { addLicensesModalClose, addLicensesModalOpen } from "../redux/actions/subscriptions";
import { sortPluginsByPopularity } from "shared-frontend/functions/products";
import {
	getInitialPluginSetup,
	addSubscriptionInfoToProduct,
	getProductGroupsByParentSlug,
	SITE_TYPE_PLUGIN_SLUG_MAPPING,
} from "shared-frontend/functions/productGroups";
import { getAddSubscriptionModal, isRequestingSubscriptions } from "../redux/selectors/ui/subscriptions";
import { getSites } from "shared-frontend/redux/selectors/entities/sites";
import { getActivatableSubscriptionsWithProductInformation } from "shared-frontend/redux/selectors/entities/subscriptions";
import { getProducts } from "shared-frontend/redux/selectors/entities/products";
import { getProductGroups } from "shared-frontend/redux/selectors/entities/productGroups";
import { getSite } from "../redux/selectors/ui/site";
import { addProvisioningInfoToPluginForSite } from "shared-frontend/functions/provisioning";

/* eslint-disable-next-line max-statements */
export const mapStateToProps = ( state, ownProps ) => {
	const siteId = ownProps.match.params.id;
	const sites = getSites( state );
	if ( ! sites.byId.hasOwnProperty( siteId ) ) {
		return {
			loadingSite: true,
		};
	}
	const site = sites.byId[ siteId ];

	const activatableSubscriptions = getActivatableSubscriptionsWithProductInformation( state );

	// Set in the subscription whether it is enabled on the site.
	activatableSubscriptions.map( subscription =>
		Object.assign(
			subscription, {
				isEnabled: !! site.subscriptions && site.subscriptions.includes( subscription.id ),
			} ),
	);

	// Get the productGroups that contain our plugin product variations.
	const allProductGroups    = getProductGroups( state );
	const pluginProductGroups = getProductGroupsByParentSlug(
		SITE_TYPE_PLUGIN_SLUG_MAPPING[ site.type ],
		allProductGroups,
		true,
	);

	let allPluginProducts = getProducts( state );
	// Remove products not from the US-shop.
	allPluginProducts     = allPluginProducts.filter( product => product.sourceShopId === 1 );
	// Remove products that do not have a productGroup that gives access to a plugin.
	allPluginProducts = allPluginProducts.filter( product =>
		product.productGroups.some( pg => pluginProductGroups.map( pg2 => pg2.id ).includes( pg.id ) ),
	);
	// Sort the products on billing term, making sure those with a year billing term are first.
	allPluginProducts.sort( ( a ) => ( a.billingTerm === "year" ) ? -1 : 1 );
	// Remove products that have an already existing glNumber. To prevent annual and monthly products from counting twice.
	allPluginProducts     = _uniqBy( allPluginProducts, "glNumber" );
	allPluginProducts     = sortPluginsByPopularity( allPluginProducts );

	// Enrich and adjust the products.
	allPluginProducts = allPluginProducts.map( product => getInitialPluginSetup( product, pluginProductGroups ) );
	allPluginProducts = allPluginProducts.map( product => addSubscriptionInfoToProduct( product, activatableSubscriptions ) );
	allPluginProducts = allPluginProducts.map( product => addProvisioningInfoToPluginForSite( product, activatableSubscriptions ) );

	// For each plugin productGroup, get the products that belong to it, and add subscription info. Then push the final result to the plugins array.
	let plugins = {};
	for ( const plugin of allPluginProducts ) {
		// Merge products that contain only a single plugin product group into the singular variant of that product.
		// Product with only a single plugin product group will be given the name of that product group.
		if ( plugins[ plugin.name ] ) {
			plugins[ plugin.name ].limit += plugin.limit;
			plugins[ plugin.name ].used  += plugin.used;

			plugins[ plugin.name ].hasSubscriptions = plugins[ plugin.name ].limit > 0;
			plugins[ plugin.name ].isEnabled        = plugins[ plugin.name ].isEnabled || plugin.isEnabled;
			plugins[ plugin.name ].subscriptionId   = plugins[ plugin.name ].subscriptionId || plugin.subscriptionId;
			plugins[ plugin.name ].manageButtonSubscriptionId =
				plugins[ plugin.name ].manageButtonSubscriptionId || plugin.manageButtonSubscriptionId;

			// eslint-ignore-next-line max-len -- splitting this up doesn't make it better.
			plugins[ plugin.name ].isAvailable = ( plugins[ plugin.name ].limit > plugins[ plugin.name ].used || plugins[ plugin.name ].isEnabled );

			plugins[ plugin.name ].accessGivenByProvisionedSubscription =
				plugins[ plugin.name ].accessGivenByProvisionedSubscription || plugin.accessGivenByProvisionedSubscription;
			plugins[ plugin.name ].provisionerAllowsSiteChange =
				plugins[ plugin.name ].provisionerAllowsSiteChange || plugin.provisionerAllowsSiteChange;
			plugins[ plugin.name ].provisionerName   = plugins[ plugin.name ].provisionerName || plugin.provisionerName;
		} else {
			plugins[ plugin.name ] = plugin;
		}
	}

	// Not a perfect solution. but I could not find a foolproof way of identifying the plugins that should always be shown.
	/* eslint-disable no-inline-comments */
	const GLNumbersToAlwaysShow = [
		"82102", // Video SEO for WordPress plugin
		"82103", // Local SEO for WordPress plugin
		"82104", // News SEO for WordPress plugin
		"82105", // Yoast WooCommerce SEO plugin
		"82109", // Yoast plugin subscription
		"82120", // Yoast SEO Premium
	];
	/* eslint-enable no-inline-comments */

	plugins = Object.values( plugins );
	plugins = plugins.filter( plugin =>
		plugin.hasSubscriptions ||
		GLNumbersToAlwaysShow.includes( plugin.glNumber ) ||
		plugin.productGroups.some( pg => pg.slug === "all-plugins" ),
	);

	const addSubscriptionModal = getAddSubscriptionModal( state );
	const loadingSubscriptions = isRequestingSubscriptions( state );
	const uiSite = getSite( state );
	const disablePlatformSelect = plugins.some( ( plugin ) => plugin.isEnabled );

	return {
		addSubscriptionModal,
		site,
		plugins,
		loadingSubscriptions,
		uiSite,
		disablePlatformSelect,
	};
};

export const mapDispatchToProps = ( dispatch, ownProps ) => {
	dispatch( loadSites() );
	const siteId = ownProps.match.params.id;

	return {
		onMoreInfoClick: () => {},
		onToggleDisabled: ( subscriptionId ) => {
			dispatch( addLicensesModalOpen( subscriptionId ) );
		},
		onClose: () => {
			dispatch( addLicensesModalClose() );
		},
		onToggleSubscription: ( subscriptionId, productSourceId, productName, enabled ) => {
			/* eslint-disable camelcase */
			window.dataLayer.push( {
				product_name: productName,
				product_id: productSourceId,
				toggle_state: enabled,
				event: "toggleProductSubscription",
			} );
			/* eslint-enable camelcase */

			if ( enabled ) {
				dispatch( siteAddSubscription( siteId, subscriptionId ) );
			} else {
				dispatch( siteRemoveSubscription( siteId, subscriptionId ) );
			}
		},
		onChange: ( url ) => {
			dispatch( updateSiteUrl( url ) );
		},
		onRemove: () => {
			// eslint-disable-next-line
			if ( window.confirm( "Are you sure you want to remove this site from MyYoast?" ) ) {
				dispatch( siteRemove( siteId ) );
			}
		},
		onConfirmPlatformChange: ( id, type ) => {
			dispatch( siteChangePlatform( id, type ) );
		},
	};
};

const SitePageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( SitePage );

export default SitePageContainer;

/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

/* Internal dependencies */
import { Button, Modal } from "@yoast/ui-library";
import * as styles from "./ComposerHelpStyles.scss";

const GlNumberMapping = {
	82103: "yoast/wordpress-seo-local",
	82106: "yoast/wordpress-seo-local-woocommerce",
	82101: "yoast/wordpress-seo-premium",
	82102: "yoast/wpseo-video",
	82105: "yoast/wpseo-woocommerce",
	82104: "yoast/yoast-news-seo",
	82108: "yoast-seo-for-typo3/yoast_seo_premium",
};
const WordPressGlNumbers = [ "82103", "82106", "82101", "82102", "82105", "82104" ];

/**
 * Renders the ComposerHelp component.
 *
 * @param {Object} props The props to use.
 * @param {Function} props.onClose The function to execute when the got it button is clicked.
 *
 * @returns {ReactElement} A react component rendering the composer help modal.
 */
function ComposerHelp( props ) {
	return (
		<>
			<Modal.Title as="h2" size="2">
				<FormattedMessage
					id="composer-help.header"
					defaultMessage="How to install the {product} using composer"
					values={ {
						product: props.productName,
					} }
				/>
			</Modal.Title>
			<div className={ styles.contentWrapper }>
				<p>
					<FormattedMessage
						id="composer-help.introduction"
						defaultMessage="Composer is a tool used by many developers to install and update plugins.
				Through MyYoast you can use Composer to get easy access to your premium plugins.
				Follow the instructions below to get started!"
					/>
				</p>
				{ props.composerToken
					? <div className={ styles.contentWrapper }>
						<div>
							<FormattedMessage
								id="composer-help.register-token"
								defaultMessage="You can register your token with composer by running the command below:"
							/>
							<pre>composer config -g http-basic.my.yoast.com token { props.composerToken.id }</pre>
						</div>
						<div>
							<FormattedMessage
								id="composer-help.register-token"
								defaultMessage="You can then add our secure repository by running the following command:"
							/>
							<pre>composer config repositories.my-yoast composer https://my.yoast.com/packages/</pre>
						</div>
						<div>
							<FormattedMessage
								id="composer-help.register-token"
								defaultMessage="Now you can install the {product} by running:"
								values={ {
									product: props.productName,
								} }
							/>
							<pre>composer require { GlNumberMapping[ props.productGlNumber ] }</pre>
						</div>
						{ WordPressGlNumbers.includes( props.productGlNumber ) &&
						<div className={ styles.combinedWrapper }>
							<FormattedMessage
								id="composer-help.require-autoload"
								defaultMessage="In order to use the {product} with Composer you will have to require Composer's {autoload} file.
							We recommend adding this to your {wpConfig} file."
								values={ {
									product: props.productName,
									autoload: <pre>vendor/autoload.php</pre>,
									wpConfig: <pre>wp-config.php</pre>,
								} }
							/>
						</div>
						}
					</div>
					: <div>
						<FormattedMessage
							id="composer-help.create-token"
							defaultMessage="Before you can install the {product} using composer you will need to create a
						token to access our secure repository. You can do this on the Account page in the Profile section
						or by clicking the button below."
							values={ {
								product: props.productName,
							} }
						/>
					</div>
				}
			</div>
			<div className={ styles.buttonWrapper }>
				{ ! props.composerToken &&
				<Button
					type="submit"
					onClick={ props.createComposerToken }
				>
					<FormattedMessage id="profile.create-sites.create" defaultMessage="Create token" />
				</Button>
				}
				<Button
					onClick={ props.onClose }
					variant="secondary"
				>
					<FormattedMessage id="gettingStarted.gotIt" defaultMessage="Got it" />
				</Button>
			</div>
		</>
	);
}

ComposerHelp.propTypes = {
	onClose: PropTypes.func.isRequired,
	productName: PropTypes.string,
	productGlNumber: PropTypes.string.isRequired,
	composerToken: PropTypes.object,
	createComposerToken: PropTypes.func.isRequired,
};

ComposerHelp.defaultProps = {
	productName: "this product",
	composerToken: null,
};

export default injectIntl( ComposerHelp );
